import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DELETE_OFFLINE_DONATION, GET_DONATION_BY_ID, GET_EMAIL_RECEIPT, RESEND_EMAIL, UPDATE_DONATION_STATUS } from '../../../constants/endpoints';
import getFormattedDate, { dateTimeFormat } from '../../../utils/date-format';
import { getUserInfo } from '../../../utils/user-info';
import CardType from '../../../shared/card-type/card-type';
import { PAYMENT_STATUS } from '../../../constants/payment-status.constant';
import { paymentStatusList } from '../../../data-list/data-list';
import { useForm } from 'react-hook-form';
import ConfirmationDialog from '../../../shared/confirmation-dialog/confirmation-dialog';

function DonationDetail(props) {
    const navigate = useNavigate();
    const confirmationDialogRef = useRef(null);
    const {donation_id, campaign_id} = useParams();
    const [donationDetail, setDonationDetail] = useState({});
    const [emailTemplate, setEmailTemplate] = useState(null);
    const [resendEmailLoader, setResendEmailLoader] = useState(false);
    const [isEditStatus, setIsEditStatus] = useState(false);
    const [loader, setLoader] = useState(false);
    const orgInfo = getUserInfo();
    const {register, formState:{errors}, watch, setValue} = useForm();
    const fieldWatcher = watch();

    const getDonationByID = () => {
        axios(GET_DONATION_BY_ID + donation_id)
        .then((res) => {
            if(res?.data?.status){
                setDonationDetail(res?.data?.response);
            }
        }).catch((error) => {

        })
    }

    const getEmailTemplate = () => {
        axios.get(GET_EMAIL_RECEIPT+campaign_id)
        .then((res) => {
            if(res?.data?.status){
                setEmailTemplate(res?.data?.response)
            }else{
                setEmailTemplate({
                    email_subject: "Thanks for donating to {organization_name}",
                    email_body:`<p>Dear {donor_first_name},</p>
                    <p>This is a receipt for your generous donation to {organization_name}.</p>
                    <p>{donation_details}</p>
                    <p>Thank you,</p>
                    <p>{organization_name}</p>`
                })
            }
        }).catch((error) => {
            
        })
    }

    const resendEmail = () => {
        setResendEmailLoader(true);
        const body = {
            template: {
                email_body: emailTemplate?.email_body,
                email_subject: emailTemplate?.email_subject,
            },
            params: donationDetail
        }
        axios.post(RESEND_EMAIL, body)
        .then((res) => {
            setResendEmailLoader(false);
            if(res?.data?.status){
                alert(res?.data?.message);
            }
        }).catch((error) => {
            setResendEmailLoader(false);
            alert("Unable to send email.");
        })
    }

    const generatedEmailBody = () => {
        if(emailTemplate?.email_body && donationDetail?.id){
            let body = emailTemplate?.email_body?.replaceAll('{organization_name}',`<label class='text-black text-bold'> ${donationDetail.organization_name}</label>`)
            .replaceAll('{campaign_name}', `Campaign: <label class='text-black text-bold'> ${donationDetail?.campaign_title}</label>`)
            .replaceAll('{donor_first_name}', `<label class='text-black text-bold'> ${donationDetail?.first_name} ${donationDetail?.last_name}</label>`)
            .replaceAll('{amount}', `Amount: <label class='text-black text-bold'>$${donationDetail?.total_amount}</label>`)
            .replaceAll('{interval}', `Donation Type: <label class='text-black text-bold'> ${donationDetail?.donation_type}</label>`)

            let donationDetailsTemplate = `
            <p>Organization: <b>${donationDetail?.organization_name}</b><p/>
            <p>Campaign: <b>${donationDetail?.campaign_title}</b><p/>
            <p>Donor Name: <b>${donationDetail?.first_name +' '+donationDetail?.last_name}</b><p/>
            <p>Amount: <b>$${(donationDetail.amount/100)}</b><p/>
            <p>Donation Interval: <b>${donationDetail?.donation_type}</b><p/>
            <p>Donated At: <b>${dateTimeFormat(donationDetail?.created_at)}</b><p/>
            <p>Payment Method: <b>${(donationDetail?.card_type?.charAt(0)?.toUpperCase() + donationDetail?.card_type.slice(1)?.toLowerCase())} (${donationDetail?.last_4_digits} - ${donationDetail?.payment_type})</b><p/>
            `;

            body = body?.trim()?.replaceAll("<p>{donation_details}</p>", `<div>${donationDetailsTemplate}</div>`);

            body += '<p></p><p>Powered by <a class="text-ma-primary" href="https://mymasjidal.com/" target="_blank">Masjidal</a></p>'
            return body;
        }else{
            return ""
        }   
    }

    const generateEmailSubject = () => {
        if(emailTemplate?.email_subject && donationDetail?.id){
            const subject = emailTemplate?.email_subject?.replaceAll('{organization_name}',donationDetail.organization_name)
            .replaceAll('{campaign_name}', donationDetail?.campaign_title)
            .replaceAll('{donor_first_name}', donationDetail?.first_name)
            .replaceAll('{donor_last_name}', donationDetail?.last_name)
            .replaceAll('{amount}', '$'+donationDetail?.total_amount)
            .replaceAll('{interval}', donationDetail?.donation_type)
            return subject;
        }else{
            return ""
        }   
    }
    

    const updateDonationStatus = () =>{
        console.log(fieldWatcher)
        setLoader(true);
        axios.patch(UPDATE_DONATION_STATUS + donation_id, fieldWatcher)
        .then((res) => {
            setLoader(false);
            if(res?.data?.status){
                setIsEditStatus(false);
                getDonationByID();
            }
        }).catch((error) => {
            setLoader(false);
        })
    }

    const deleteOfflineDonation = (donation_id) => {
        axios.delete(DELETE_OFFLINE_DONATION + donation_id)
        .then((res) => {
            if(res?.data?.status){
                navigate("/donations");
            }else{
                alert(res?.data?.message ? res?.data?.message : "Failed to delete the donation.");
            }
        }).catch((error) => {
            alert("Failed to delete the donation.");
        })
    }

    useEffect(() => {
        getDonationByID();
        getEmailTemplate();
    },[]);

    return (
        <div className='row'>
            <div className='col-xxl-12'>
                <h5 className='font-weight-semibold text-black'>Donation Detail</h5>
            </div>

            <div className='col-xxl-4'>
                <div className='card'>
                    <div className='card-body'>
                        <dl className='text-black'>
                            <dt className='d-flex justify-content-between align-items-center'>
                                <span>Campaign</span>
                                {
                                    donationDetail?.platform_used === 'Offline' ?
                                        <div>
                                            <i onClick={() => {
                                                confirmationDialogRef.current.setDialogTitle('Confirm Deletion');
                                                confirmationDialogRef.current.setMessage(`Are you sure you want to delete this donation?`);
                                                confirmationDialogRef.current.setButtonLabel("Delete Donation");
                                                confirmationDialogRef.current.openDialog(donationDetail?.id);
                                            }} title='Delete' className="cursor-pointer fa-solid fa-trash me-2"></i>
                                            <i onClick={() => {
                                                navigate(`/offline-donation/${donationDetail?.campaign_id}/${donationDetail?.campaign_title}/${donationDetail?.type}/${donationDetail?.id}`)
                                            }} title="Edit" className="cursor-pointer fa-solid fa-pen-to-square"></i>
                                        </div> : ''
                                }
                                
                            </dt>
                            <dd>{donationDetail?.campaign_title ? donationDetail?.campaign_title : '-'}</dd>
                            <dt>Payment Status</dt>
                            <dd>
                                {
                                    !isEditStatus ?
                                    <div>
                                    <span title={donationDetail?.payment_log ? donationDetail?.payment_log : 'Payment is in process'} className={
                                        `text-bold text-capitalize ${donationDetail?.payment_status === PAYMENT_STATUS.SUCCESS ? 'text-success' :
                                            donationDetail?.payment_status === PAYMENT_STATUS.FAILED ? 'text-danger' :
                                                !donationDetail?.payment_status ? 'text-warning' : ''
                                        }`
                                    }>{donationDetail?.payment_status === null ? 'Pending' : donationDetail?.payment_status}</span>
                                    {
                                        donationDetail?.payment_status === null ? 
                                        <i title='Edit' onClick={() => {
                                            setIsEditStatus(true);
                                        }} className="mx-2 fa-regular fa-pen-to-square cursor-pointer"></i> : ''
                                    }
                                        </div> : isEditStatus ? <div className='d-flex justify-content-between align-item-center'>
                                            <div className="form-group w-60">
                                                <Form.Select {...register("payment_status", {
                                                    onChange: (event) => {
                                                        if(event.target.value === 'failed'){
                                                            setValue("payment_log", "Payment failed");
                                                        }else{
                                                            setValue("payment_log", "Payment initiated successfully");
                                                        }
                                                    },
                                                    validate:{
                                                        required: (value) => {
                                                            if(!value) return "Field is required"
                                                            return true
                                                        }
                                                    }
                                                })}>
                                                    <option value={""}>Select Status</option>
                                                    {
                                                        paymentStatusList.map((item, index) => {
                                                            return <option
                                                                key={'status ' + index}
                                                                value={item.value}>{item.label}</option>
                                                        })
                                                    }
                                                </Form.Select>
                                                <input type='hidden' {...register("payment_date",{
                                                    value: donationDetail?.created_at
                                                })} />
                                                <input type='hidden' {...register("payment_retry_count",{
                                                    value: 0
                                                })} />
                                                <input type='hidden' {...register("payment_log")} />
                                            </div>
                                            <button
                                            disabled={loader} 
                                            onClick={() => {
                                                if(!fieldWatcher?.payment_status){
                                                    setIsEditStatus(false);
                                                }else{
                                                    updateDonationStatus();
                                                }
                                            }}
                                            className={`btn btn-outline-primary p-2 mb-0 ${loader ? 'button--loading position-relative ' : ''}`}>
                                                {
                                                    !fieldWatcher?.payment_status ? 'Cancel' : 'Update'
                                                }
                                            </button>
                                        </div> : ''
                                }
                            </dd>
                            <dt>Donation Designation</dt>
                            <dd>{donationDetail?.donation_designation ? donationDetail?.donation_designation : '-'}</dd>
                            <dt>Donor Comments</dt>
                            <dd>{donationDetail?.donor_comments ? donationDetail?.donor_comments : '-'}</dd>

                            <dt>Donated At</dt>
                            <dd>{
                                donationDetail?.created_at ? 
                                donationDetail?.platform_used === 'Offline' ? 
                                getFormattedDate(donationDetail?.created_at) :
                                dateTimeFormat(donationDetail?.created_at) : '-'
                                }</dd>

                            <dt>Name</dt>
                            <dd><Link to={`/donor-detail/${donationDetail?.donor_id}/${donationDetail?.first_name} ${donationDetail?.last_name}`}  className='text-light-blue'>{
                                (donationDetail?.first_name ? donationDetail?.first_name : '') + ' '+ (donationDetail?.last_name ? donationDetail?.last_name : '')
                            }</Link></dd>

                            <dt>Email</dt>
                            <dd><Link to={`/donor-detail/${donationDetail?.donor_id}/${donationDetail?.first_name} ${donationDetail?.last_name}`} className='text-light-blue'>{donationDetail?.email ? donationDetail?.email : '-'}</Link></dd>

                            <dt>Phone</dt>
                            <dd>{donationDetail?.phone ? donationDetail?.phone : '-'}</dd>

                            <dt>Address</dt>
                            <dd>{donationDetail?.address ? (donationDetail?.address +', ') : '-'}
                            {donationDetail?.city ? (donationDetail?.city + ', ') : ''}
                            {donationDetail?.state_province ? (donationDetail?.state_province +', ') : ''}
                            {donationDetail?.country ? (donationDetail?.country + ', ') : ''}
                            {donationDetail?.postal_code ? donationDetail?.postal_code : ''}
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div className='col-xxl-12 mt-2'>
                <Tabs
                    defaultActiveKey="donation_detail"
                >
                    <Tab eventKey="donation_detail" title="Amount & Fees">
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-6'>
                                    <dl className='mb-0 text-black'>
                                    <dt>Platform Used</dt>
                                    <dd>{donationDetail?.platform_used}</dd>
                                    <dt>Payment Type</dt>
                                    <dd>{donationDetail?.payment_type}</dd>
                                    <dt>Card Type</dt>
                                    <dd className='text-capitalize'>
                                    <CardType card_type={donationDetail?.card_type} last_4_digits={donationDetail?.last_4_digits} />
                                    </dd>
                                    <dt>Frequency</dt>
                                    <dd>{donationDetail?.donation_type}</dd>
                                    <dt>Stripe Charge ID</dt>
                                    <dd>{donationDetail?.charge_id || "-"}</dd>
                                    <dt>Stripe Payment Intent ID</dt>
                                    <dd>{donationDetail?.payment_intent_id || '-'}</dd>
                                </dl>
                                    </div>
                                    <div className='col-6'>
                                    <dl className='mb-0 text-black'>
                                    
                                    <dt>Total Amount</dt>
                                    <dd>${donationDetail?.total_amount}</dd>
                                    <dt>Processing Fee</dt>
                                    <dd>${donationDetail?.stripe_processing_fee}</dd>
                                    <dt>Net Amount</dt>
                                    <dd>${donationDetail?.net_amount}</dd>
                                </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="receipt" title="Receipt">
                        <div className='card'>
                            <div className='card-body'>
                                <div className='d-flex justify-content-between align-items-center'>
                                <p className='text-black mb-0 text-bold'>Preview</p>
                                <button disabled={resendEmailLoader} type='button' onClick={resendEmail} className={`btn btn-ma-primary position-relative ${resendEmailLoader ? 'button--loading' : ''}`}>Resend Receipt to Donor</button>
                                </div>
                                <hr />
                                <div className='d-grid'>
                                    <p className='mb-1'>From <label className='text-black text-bold'>{donationDetail?.organization_email}</label></p>
                                    <p className='mb-1'>To <label className='text-black text-bold'>{donationDetail?.email}</label></p>
                                    <p className='mb-1'>Subject <label className='text-black text-bold' dangerouslySetInnerHTML={{__html:generateEmailSubject()}}></label></p>
                                </div>
                                <hr />
                                <div className='row'>
                                {/* style={{
                                        backgroundColor:'#005b9717'
                                    }} */}
                                    <div className='col-xxl-6 p-3' >
                                        {/* <div className='row mb-2'>
                                            <div className='text-center'>
                                            <img src='/img/masjid/masjid_noor.png'className='mt-1 w-10'/>
                                            </div>
                                        </div> */}
                                        {/* <p>Dear Ali,</p>
                                        <p>This is a receipt for your gracious donation to <b>{orgInfo?.masjid_name}</b>.</p> */}
                                        <div className='d-grid text-black' dangerouslySetInnerHTML={{__html:generatedEmailBody()}}>
                                            
                                        {/* <p className='mb-1'>Organization: <label className='text-black text-bold'>Test Organization</label></p>
                                        <p className='mb-1'>Campaign: <label className='text-black text-bold'> Masjid Construction Campaign</label></p>
                                        <p className='mb-1'>Donor Name: <label className='text-black text-bold'>Wasim Essani</label></p>
                                        <p className='mb-1'>Amount: <label className='text-black text-bold'>$52.20</label></p>
                                        <p className='mb-1'>Donation Interval: <label className='text-black text-bold'>One-time</label></p>
                                        <p className='mb-1'>Receipt # <label className='text-black text-bold'>Thanks for donation to Test Organization</label></p>
                                        <p className='mb-1'>Donated At: <label className='text-black text-bold'>05/16/2021 03:41:52</label></p>
                                        <p className='mb-1'>Payment Method: <label className='text-black text-bold'>American Express 1004</label></p>
                                        <p className='mb-1'>Donor Address: <label className='text-black text-bold'>114 hollow drive DANVILLE, PA 17821 United States</label></p>
                                        <p className='mb-1'>Phone: <label className='text-black text-bold'>0342-2996875</label></p> */}
                                        </div>
                                        <p>{orgInfo?.organization_name}</p>
                                        {/* <p className='f-14'>If you did not make this donation or have received this email by mistake, please click here. This link is valid for 24 hours.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            {/* confirmation dialog start */}
            <ConfirmationDialog ref={confirmationDialogRef} executeAction={deleteOfflineDonation} />
            {/* confirmation dialog end */}
        </div>
    );
}

export default DonationDetail;