import React, { useEffect, useRef, useState } from 'react';
import MonthlyTotal from '../../../shared/recurring-plans-widgets/monthly-total';
import ActivePlans from '../../../shared/recurring-plans-widgets/active-plans';
import NoDataComponent from '../../../shared/no-data/no-data';
import axios from 'axios';
import { DOWNLOAD_RECURRING_PLANS, GET_ALL_RECURRING_PLANS } from '../../../constants/endpoints';
import getFormattedDate from '../../../utils/date-format';
import RecurringFilters from './filters/recurring-filter';
import { Button } from 'react-bootstrap';
import { downloadBlob } from '../../../utils/utils';
import Loader from '../../../shared/loader/loader';
import { Link } from 'react-router-dom';
import CardType from '../../../shared/card-type/card-type';

function RecurringPlans(props) {
    const filterRef = useRef(null);
    const [dataLoader, setDataLoader] = useState(false);
    const columns = [
        'Donor',
        'Campaign',
        'Amount',
        'Total',
        'Started Date',
        'Last Donation',
        'Next Donation',
        'Card Type',
        'Status'
    ];

    const [recurringPlans, serRecurringPlans] = useState([]);

    //page index for table
    
    const changePage = (pageIndex) => {
        filterRef.current.setPageIndex(pageIndex);
        getAllRecurringPlans(filterRef.current.getValues() , pageIndex);
    }

    const getAllRecurringPlans = (formValues, pageIndex) => {
        setDataLoader(true);
        let formData = {
            page_index: pageIndex ? pageIndex : 0
        }
        formData = formValues ? {...formValues,...formData} : {...{text_value:''}, ...formData};

        axios.post(GET_ALL_RECURRING_PLANS, formData)
        .then((res) => {
            setDataLoader(false);
            //for testing amount calculation
            // res.data.response[0].amount = "3";
            // res.data.response[0].total_amount = "9";
            
            res?.data?.status ? serRecurringPlans(res?.data?.response) : serRecurringPlans([]);
        }).catch((error) =>{
            setDataLoader(false);
        })
    }

    useEffect(() => {
        getAllRecurringPlans();
    }, [])

    const [filtersValue, setFiltersValue] = useState({});

    const [fileLoader, setFileLoader] = useState(false);

    const downloadCSV = () => {
        setFileLoader(true);
        
        axios.post(DOWNLOAD_RECURRING_PLANS, filterRef.current.getValues(),{ responseType: 'blob' })
        .then((res) => {
          setFileLoader(false);
          
          if(res?.data instanceof Blob){
            downloadBlob(res?.data, 'recurring_plans_');
          }else{
            alert('Failed to download file.');
          }
        }).catch((error) => {
          setFileLoader(false);
          alert('Failed to download file.');
        })
    }

    return (
        <div className='row'>
            <div className='col-xl-12'>
                <div>
                    <h5 className='font-weight-semibold text-black'>Recurring Plans</h5>
                </div>
            </div>
            
            {/* injecting filters start */}
            <RecurringFilters
            fileLoader={fileLoader}
            downloadCSV={downloadCSV}
            ref={filterRef} 
            setFiltersValue={setFiltersValue}
            getAllRecurringPlans={getAllRecurringPlans}/>
            {/* injecting filters end */}
            <div className='row mt-3'>
                {/* <MonthlyTotal /> */}
                <ActivePlans filtersValue={filtersValue} recurringPlans={recurringPlans} />
            </div>

        <div className='row mt-3 position-relative'>
        <div className='col-xl-12'>
            <div className='card'>
                <div className="table-responsive position-relative">
                    {
                        !dataLoader ? 
                        <table className="table align-items-center ">
                        <thead className='text-center prayers-table-header white'>
                            <tr>
                                {
                                    columns.map((item, index) => {
                                        return <th key={index}>{item}</th>
                                    })
                                }
                                {/* <th>ID & Date</th>
                            <th>Donor</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Donor Comments</th>
                            <th>Internal Notes</th> */}
                            </tr>
                        </thead>
                        <tbody className='prayer-table-body text-center'>
                            {
                                recurringPlans.map((item,index) => {
                                    return <tr key={'plan_' +index}>
                                    <td>
                                    <Link to={`/donor-detail/${item?.donor_id}/${item?.first_name +' '+item?.last_name}`} className='text-light-blue'>{item?.first_name +' '+item?.last_name}</Link>
                                    <p className='mb-0'>{item?.email}</p>
                                    </td>
                                    <td>{item?.campaign_title}</td>
                                    <td>
                                        ${item?.amount} / {item?.donation_interval}
                                    </td>
                                    {/* <td>${parseFloat((item?.total_amount + item?.amount))?.toFixed(2)}</td> */}
                                    <td>${
                                            ((item?.total_amount ? parseFloat(item?.total_amount?.replaceAll(",", "")) : 0))?.toFixed(2)
                                            // + (item?.amount ? parseFloat(item?.amount) : 0)
                                        }</td>
                                    <td>{getFormattedDate(item?.start_date)}</td>
                                    <td>{getFormattedDate(item?.last_donated_date)}</td>
                                    <td>
                                        {
                                            item?.status?.toLowerCase() !== 'paused' ? 
                                            getFormattedDate(item?.next_donation_date) : '-'
                                        }
                                    </td>
                                    <td>
                                        <CardType card_type={item?.card_type} last_4_digits={item?.last_4_digits} />
                                    </td>
                                    <td className='text-capitalize'>
                                        {item?.status}
                                        <p className='mb-0'>
                                        <Link
                                        to={`detail/${item?.id}`} className='text-ma-primary'>
                                            View Details
                                        </Link>
                                        </p>
                                    </td>
                                </tr>
                                })
                            }
                           
                        </tbody>

                    </table> : ''
                    }

                    {/* no data message */}
                    {
                        
                        !dataLoader && recurringPlans?.length === 0 ?
                        <NoDataComponent message={'No Recurring Plans Found'} /> : ''
                    }
                    
                </div>
            </div>

            <div className='mt-2 text-right'>
                    {
                        filterRef?.current?.pageIndex ? 
                        <Button
                        onClick={() => {
                            const index = filterRef.current.pageIndex - 1;
                            filterRef.current.setPageIndex(index);
                            changePage(index)
                        }}
                         className='me-2' type='button'
                        variant="primary">
                        Previous
                    </Button> : ''
                    }

                    <Button disabled={recurringPlans?.length > 0 ? false : true} onClick={() => {
                        const index = filterRef.current.pageIndex + 1;
                        filterRef.current.setPageIndex(index);
                        changePage(index)
                    }} type='button'
                        variant="primary">
                        Next
                    </Button>
                </div>
        </div>
        {
            dataLoader ? 
            <Loader /> : ''
        }
    </div>
        </div>
    );
}

export default RecurringPlans;