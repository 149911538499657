import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../shared/fields-errors/field-error';
import axios from 'axios';
import { SAVE_DONATION_PLEDGE } from '../../../constants/endpoints';

const PledgeUserInfo = React.forwardRef((props, ref) => {

    const { register, clearErrors, getValues, handleSubmit } = useFormContext();
    const [ payLater, setPayLater ] = useState(false);
    const [ pledgeLoader, setPledgeLoader ] = useState(false);
    useImperativeHandle(ref, () => {
        return {
            setPayLater: setPayLater,
            payLater: payLater
        };
    });

    useEffect(() => {
        clearErrors();
    },[]);

    const saveDonationPledge = () => {
        setPledgeLoader(true);
        const payload = {
            amount: parseFloat(props?.returnSelectedAmount()),
            masjid_id: props?.campaignDetail?.masjid_id,
            campaign_id: props?.campaignDetail?.donation_amount?.campaign_id,
            donation_designation: props?.form?.donation_designation,
            donor_comments: props?.form?.donor_comments,
            donation_type: props?.selectedDonationType,
            preferred_payment_option: getValues()?.preferred_payment_option,
            first_name: getValues()?.first_name,
            last_name: getValues()?.last_name,
            email: getValues()?.email,
            phone: getValues()?.phone
        }
        
        axios.post(SAVE_DONATION_PLEDGE, payload)
        .then((res) => {
            debugger
            setPledgeLoader(false);
            if(res?.data?.status){
                props?.setFormStep('pledge-confirmation');
            }else{
                alert(res?.data?.message);
            }
        }).catch((error) => {
            setPledgeLoader(false);
            alert("Failed to save pledge information");
        });
    }

    return (
        <div className='col-12'>
            <form onSubmit={handleSubmit(saveDonationPledge)}>
            <div className='row'>
                <div className='col-lg-6 mt-4'>
                    <div className="form-group"><label className="form-control-label">
                    <span className='text-danger'>*</span> First Name</label>
                        <input onInput={(() => clearErrors('first_name'))} placeholder="First Name"
                            {...register("first_name", {
                                required: true
                            })}
                            type="text"
                            className="form-control-alternative form-control" /></div>
                    {props?.errors?.first_name && props?.errors?.first_name?.type === 'required' ? <FieldError message={'First name is required'} /> : ''}
                </div>
                <div className='col-lg-6 mt-4'>
                    <div className="form-group"><label className="form-control-label">
                    <span className='text-danger'>*</span> Last Name</label>
                        <input onInput={(() => clearErrors('last_name'))}
                            {...register("last_name", {
                                required: true
                            })}
                            placeholder="Last Name" type="text"
                            className="form-control-alternative form-control" /></div>
                    {props?.errors?.last_name && props?.errors?.last_name?.type === 'required' ? <FieldError message={'Last name is required'} /> : ''}
                </div>
                {
                    (props?.campaignDetail?.enable_anonymous && 
                        !props?.campaignDetail?.activate_pledge && payLater) ?
                    <div className="form-group mt-2">
                    <label className="form-control-label d-flex align-items-center">
                        <input {...register('make_anonymous_donation',{
                            value: false
                        })} className="me-1" type="checkbox" />
                        Make donation anonymous
                    </label>
                </div> : ''
                }
                <div className={`${(props?.campaignDetail?.activate_pledge && payLater) ? 'col-lg-6' : 'col-lg-12'} mt-4`}>
                    <div className="form-group"><label className="form-control-label">
                    <span className='text-danger'>*</span> Email</label>
                        <input onInput={(() => clearErrors('email'))}
                            {...register("email", {
                                required: true,
                                pattern: {
                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message: "Invalid email."
                                }
                            })} placeholder="Email" type="text"
                            className="form-control-alternative form-control" /></div>
                    {props?.errors?.email ? <FieldError message={
                        props?.errors?.email?.type === 'required' ?
                            'Email is required' :
                            props?.errors?.email?.type === 'pattern' ?
                                props?.errors?.email?.message : ''
                    } /> : ''}
                </div>

                {
                    (props?.campaignDetail?.collect_phone || (props?.campaignDetail?.activate_pledge && payLater)) ?
                        <div className={`${(props?.campaignDetail?.activate_pledge && payLater) ? 'col-lg-6' : 'col-lg-12'} mt-4`}>
                            <div className="form-group">
                            
                                <label className="form-control-label">
                                {
                                        (props?.fieldValidations?.phone || (props?.campaignDetail?.activate_pledge && payLater)) ?
                                        <span className='text-danger'>*</span> : ""
                                    } Phone</label>
                                <input placeholder="Phone"
                                    {...register('phone', {
                                        validate:{
                                            required :(value) => {
                                                if(!value && (props?.fieldValidations?.phone || (props?.campaignDetail?.activate_pledge && payLater))){
                                                    return "The field is required"
                                                }
                                                return true
                                            }
                                        }
                                    })}
                                    type="text" className="form-control-alternative form-control" />
                            </div>
                            {props?.errors?.phone ? <FieldError message={props?.errors?.phone?.message} /> : ''}
                        </div> : ''
                }
                

                {/* preferred payment option start */}
                {
                    props?.campaignDetail?.activate_pledge && payLater ? 
                    <div className='col-lg-12 mt-4'>
                    <div className="form-group"><label className="form-control-label"><span className='text-danger'>*</span>
                        Preferred Payment Option</label>
                        <label className="form-control-label d-flex align-items-center">
                            <input {...register("preferred_payment_option", {
                                required: {
                                    value: true,
                                    message: "Payment option is required"
                                }
                            })} className='me-1' type="radio" value={'ach'} /> ACH
                        </label>
                        <label className="form-control-label d-flex align-items-center">
                            <input {...register("preferred_payment_option", {
                                required: {
                                    value: true,
                                    message: "Payment option is required"
                                }
                            })} className='me-1' type="radio" value={'check'} /> Check
                        </label>
                        <label className="form-control-label d-flex align-items-center">
                            <input {...register("preferred_payment_option", {
                                required: {
                                    value: true,
                                    message: "Payment option is required"
                                }
                            })} className='me-1' type="radio" value={'credit_card'} /> Credit Card
                        </label>
                        <label className="form-control-label d-flex align-items-center">
                            <input {...register("preferred_payment_option", {
                                required: {
                                    value: true,
                                    message: "Payment option is required"
                                }
                            })} className='me-1' type="radio" value={'other'} /> Other
                        </label>
                    </div>
                    {props?.errors?.preferred_payment_option ?
                        <FieldError message={props?.errors?.preferred_payment_option?.message} /> : ''}
                </div> : ''
                }
                {/* preferred payment option end */}
                {
                    props?.campaignDetail?.collect_address ?
                        <>
                         <div className='col-lg-12 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                    {
                                        props?.fieldValidations?.address_required ?
                                        <span className='text-danger'>*</span> : ""
                                    }
                                    Address</label>
                                    <input placeholder="Address"
                                        {...register('address', {
                                            validate:{
                                                required :(value) => {
                                                    if(!value && props?.fieldValidations?.address_required){
                                                        return "The field is required"
                                                    }
                                                    return true
                                                }
                                            }
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                                {props?.errors?.address ? <FieldError message={props?.errors?.address?.message} /> : ''}
                            </div>
                            <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                {
                                        props?.fieldValidations?.city_required ?
                                        <span className='text-danger'>*</span> : ""
                                }   City</label>
                                    <input placeholder="City"
                                        {...register('city', {
                                            validate:{
                                                required :(value) => {
                                                    if(!value && props?.fieldValidations?.city_required){
                                                        return "The field is required"
                                                    }
                                                    return true
                                                }
                                            }
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                                {props?.errors?.city ? <FieldError message={props?.errors?.city?.message} /> : ''}
                            </div>
                            <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                {
                                        props?.fieldValidations?.state_province_required ?
                                        <span className='text-danger'>*</span> : ""
                                }
                                    State / Province</label>
                                    <input placeholder="State / Province"
                                        {...register('state_province',{
                                            validate:{
                                                required :(value) => {
                                                    if(!value && props?.fieldValidations?.state_province_required){
                                                        return "The field is required"
                                                    }
                                                    return true
                                                }
                                            }
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                            </div>
                            <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                        <span className='text-danger'>*</span>Country</label>
                                    <input disabled placeholder="Country"
                                        {...register('country', {
                                            required: "Country is required",
                                            value:'USA'
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                                {props?.errors?.country ? <FieldError message={props?.errors?.country?.message} /> : ''}
                            </div>
                            <div className='col-lg-6 mt-4'>
                                <div className="form-group"><label className="form-control-label">
                                {
                                        props?.fieldValidations?.postal_code_required ?
                                        <span className='text-danger'>*</span> : ""
                                }
                                    Postal Code</label>
                                    <input placeholder="Postal Code"
                                        {...register('postal_code',{
                                            validate:{
                                                required :(value) => {
                                                    if(!value && props?.fieldValidations?.postal_code_required){
                                                        return "The field is required"
                                                    }
                                                    return true
                                                }
                                            }
                                        })}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                            </div>
                           
                           
                            {/* <div className='col-lg-12 mt-4'>
                                <div className="form-group"><label className="form-control-label">Address 2</label>
                                    <input placeholder="Address 2"
                                        {...register('address_2')}
                                        type="text" className="form-control-alternative form-control" />
                                </div>
                            </div> */}
                        </> : ''
                }
                
                {
                    props?.campaignDetail.ask_donor_to_subscribe ? 
                    <div className='col-lg-12 mt-4'>
                    <div className='form-group mt-3'>
                    <label className="form-control-label d-flex align-items-start">
                        <input
                            {...register("subscribe_to_mailing_list",{
                                value: props?.campaignDetail.ask_donor_to_subscribe_default ? true : false
                            })}
                            className="me-1 mt-1" type="checkbox" />
                        <p>
                        {props?.campaignDetail?.email_request_label}
                        </p>
                    </label>
                </div>
                </div> : ''
                }
                {/* {
    props?.campaignDetail?.donor_comments ?
        <>
            <div className='col-lg-12 mt-4'>
                <div className='d-flex align-items-center justify-content-start'>
                    <input checked={isShowComments}
                        onChange={handleShowComments} className="me-1" type="checkbox" />
                    <label className='f-14'>
                        Add Comments.
                    </label>
                </div>
            </div>

            {
                isShowComments ? 
                <div className='col-lg-12 mt-4'>
                <div className="form-group"><label className="form-control-label">Comments</label>
                    <textarea placeholder='Comments' cols="30" rows="3" className="form-control-alternative form-control"></textarea>

                </div>
            </div> : ''
            }
            
        </> : ''
} */}


                <div className="col-lg-12 mt-4">
                    <div className="text-center position-relative">
                        {/* <button
        disabled={!options?.clientSecret}
            style={{
                backgroundColor: props?.campaignDetail?.primary_color ? (props?.campaignDetail?.primary_color) : '#005B97 !important'
            }}
            type="submit" className={`btn text-white btn-md w-100 `}>
            Next
        </button> */}
                        
                        {
                            props?.campaignDetail?.activate_pledge && payLater ? 
                            <button
                            disabled={pledgeLoader}
                            style={{
                                backgroundColor: props?.campaignDetail?.primary_color ? (props?.campaignDetail?.primary_color) : '#005B97',
                                borderBottom: props?.campaignDetail?.primary_color ? ('2px solid ' + props?.campaignDetail?.primary_color) : ''
                            }}
                            type="submit" className={`${pledgeLoader ? 'position-relative button--loading' : ''} btn btn-md w-100 text-white btn-step-one`}>
                            <span >
                                <span className='f-18 mx-4'>
                                    Submit Pledge
                                </span> <i className="fa-solid fa-arrow-right f-18 "></i>
                            </span>
                        </button> : <button
                            onClick={props?.donorInfo}
                            //  disabled={!options?.clientSecret}
                            style={{
                                backgroundColor: props?.campaignDetail?.primary_color ? (props?.campaignDetail?.primary_color) : '#005B97',
                                borderBottom: props?.campaignDetail?.primary_color ? ('2px solid ' + props?.campaignDetail?.primary_color) : ''
                            }}
                            type="button" className={`btn btn-md w-100 text-white btn-step-one`}>
                            <span >
                                <span className='f-18 mx-4'>
                                    Next
                                </span> <i className="fa-solid fa-arrow-right f-18 "></i>
                            </span>
                        </button>
                        }
                       
                    </div>
                </div>
            </div>
            </form>
        </div>
    );
});

export default PledgeUserInfo;