import React from 'react';
import FieldError from '../../../../../shared/fields-errors/field-error';
import { useFormContext } from 'react-hook-form';
import { recurringOptionsList } from '../../../../../data-list/data-list';

function RecurringOptions(props) {
    const { register, unregister, setValue, formState: { errors }, watch } = useFormContext();
    const fieldsWatcher = watch();
    return (
        <>
            <div className="form-group mt-3"><label className="" >
                <span className='form-control-label text-black'>Recurring Options</span>
            </label>

                <select {...register('recurring_option',{
                    required: value => {
                        if (!value) return 'Field is required';
                        return true;
                    },
                    onChange: (e) => {
                        if(e?.target?.value !== 'end_by_date' && e?.target?.value !== 'charge_fixed_number_of_times'){
                            setValue('recurring_option_value', e?.target?.value)
                        }else{
                            setValue('recurring_option_value', null);
                        }
                    }
                })} className="form-control" placeholder="Recurring Options">
                    {recurringOptionsList.map((item, index) => {
                        return (
                            <option key={index} value={item.value}>
                                {item.label}
                            </option>
                        );
                    })}
                </select>
                {
                    errors?.recurring_option ?
                        <FieldError message={'Field is required'} /> : ''
                }
            </div>
            {
                fieldsWatcher?.recurring_option === 'charge_fixed_number_of_times' ?
                    <div className="form-group mt-3"><label>
                        <span className='form-control-label text-black'>Charge fixed number of times</span>
                    </label>

                        <input {...register('recurring_option_value', {
                            value: 0,
                            validate: {
                                required: value => {
                                    if (!value) return 'Field is required';
                                    return true;
                                },
                                min: value => {
                                    if (value <= 0) {
                                        return `Value cannot be less then or equal to 0`
                                    };
                                    return true;
                                }
                            }
                        })} placeholder="Charge fixed number of times" type="number"
                            className="form-control-alternative form-control" />

                        {
                            errors?.recurring_option_value ?
                                <FieldError message={
                                    errors?.recurring_option_value?.type === 'required' ?
                                        errors?.recurring_option_value?.message :
                                        errors?.recurring_option_value?.type === 'min' ?
                                            `Value cannot be less then or equal to 0` : ''
                                } /> : ''
                        }
                    </div> : fieldsWatcher?.recurring_option === 'end_by_date' ?
                        <div className="form-group mt-3"><label>
                            <span className='form-control-label text-black'>Select end by date</span>
                        </label>

                            <input {...register('recurring_option_value', {
                                validate: {
                                    required: value => {
                                        if (!value) return 'Field is required';
                                        return true;
                                    }
                                }
                            })} placeholder="End by date" type="date"
                                className="form-control-alternative form-control" />

                            {
                                errors?.recurring_option_value?.message ?
                                    <FieldError message={
                                        errors?.recurring_option_value?.message} /> : ''
                            }
                        </div> : ''
            }


        </>
    );
}

export default RecurringOptions;