export const intervalsList = [
    { label: 'All Intervals', value: '' },
    { label: 'One Time', value: 'One-Time' },
    { label: 'Daily Interval', value: 'Daily' },
    { label: 'Weekly Interval', value: 'Weekly' },
    // {label:'Last year', value: 'biweekly_interval'},
    { label: 'Monthly Interval', value: 'Monthly' },
    { label: 'Quarterly Interval', value: 'Quarterly' },
    { label: 'Annually Interval', value: 'Annually' }
  ];

export const recurringStatusList = [
  {label: 'All Status', value:''},
  {label: 'Active', value:'Active'},
  {label: 'Failed', value:'Failed'},
  {label: 'Paused', value:'Paused'},
  {label: 'Canceled', value:'Canceled'},
];

export const dateFilterList = [
  { label: 'All Time', value: '' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 days', value: 'last_7_days' },
  { label: 'Last 30 days', value: 'last_30_days' },
  { label: 'This month', value: 'this_month' },
  { label: 'Last month', value: 'last_month' },
  { label: 'This year', value: 'this_year' },
  { label: 'Last year', value: 'last_year' }
];

export const paymentTypeList = [
  {label:'Check', value:'check'},
  {label:'Cash', value:'cash'},
  {label:'Credit Card', value:'credit card'},
  {label:'Zelle', value:'zelle'},
  {label:'Paypal', value:'paypal'},
  {label:'External Bank Transfer', value:'bank transfer'}
];

export const emailVariables = [
  { label: 'Select Variables', value: 'select_value' },
  { label: 'Organization Name', value: ' {organization_name}' },
  { label: 'Campaign Name', value: ' {campaign_name}' },
  { label: 'Donor First Name', value: ' {donor_first_name}' },
  { label: 'Donor Last Name', value: ' {donor_last_name}' },
  { label: 'Amount', value: ' {amount}' },
  { label: 'Interval', value: ' {interval}' },
  { label: 'Donation Detail', value: ' {donation_details}' },
  { label: 'Donation Link', value: ' {donation_link}' },
];

export const emailVariablesYearlyReceipt = [
  { label: 'Select Variables', value: 'select_value' },
  { label: 'Organization Name', value: ' {{organization.organization_name}}' },
  { label: 'Donor First Name', value: ' {{first_name}}' },
  { label: 'Donor Last Name', value: ' {{last_name}}' },
  { label: 'Total Amount', value: ' {{total_amount}}' },
];

export const fiscalYearList = [
  {label:'January 1 - December 31 (Calendar Year)', value:'0-01'},
  {label:'April 1 - March 31', value:'3-01'},
  {label:'July 1 - June 30', value:'6-01'},
  {label:'August 1 - July 31', value:'7-01'},
  {label:'October 1 - September 30', value:'9-01'}
];

export const receiptStatusList = [
  {label:'Not Sent', value:'not sent'},
  {label:'Sent', value:'sent'}
];

export const recurringOptionsList = [
  {label:'None', value: 'none'},
  {label:'End by Date', value: 'end_by_date'},
  {label:'Charge fixed number of times', value: 'charge_fixed_number_of_times'},
  {label:'Allow user to select number of times', value: 'allow_user_to_select_number_of_times'},
];

export const campaignFormMappingList = [
  {label: "First Name", value:"first_name", order_value: 1},
  {label: "Last Name", value:"last_name" , order_value: 2},
  {label: "Email", value:"email", order_value: 3},
  {label: "Amount", value:"amount", order_value: 4},
  {label: "Interval", value:"donation_type", order_value: 5},
  {label: "No Of Payments", value:"recurring_option_value", order_value: 6},
  {label: "Address", value:"address", order_value: 7},
  {label: "Phone", value:"phone", order_value: 8},
  {label: "City", value:"city", order_value: 9},
  {label: "State/Province", value:"state_province", order_value: 10},
  {label: "Postal Code", value:"postal_code", order_value: 11}
];

export const paymentStatusList = [
  {label: "Success", value:"success"},
  {label: "Failed", value:"failed"}
];

export const pledgeStatusList = [
  // {label:'Select Pledge Status', value: ''},
  {label: "Unpaid", value:"Un_Paid"},
  {label: "Pending", value:"Pending"},
  {label: "Paid", value:"Paid"},
  {label: "Cancelled", value:"Cancelled"},
];
