import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FieldError from '../../../../../shared/fields-errors/field-error';
import { Button } from 'react-bootstrap';
import CampaignIconSelection from '../../../campaigns/campaign-form/shared/campaign-icon-selection';
import { ENVIRONMENT_CONSTANT } from '../../../../../constants/env.constant';

const ProductOptions = React.forwardRef((props, ref) => {
    
    useImperativeHandle(ref, () => {
        return {
            selectedSource: selectedSource
        };
      });

    const { register, setValue, watch, formState: { errors } } = useFormContext();
    const fieldsWatcher = watch();

    const setColor = (event) => {
        document.getElementById('txtHeaderColor').value = event.target.value;
    }

    const childRef = useRef(null);
  
    const handleClick = () => {
      childRef.current.handleShow();
    };
    
    const [selectedSource, setSelectedSource] = useState('');
    const toggleIconState = (url) => {
        setSelectedSource(url);
    }

    useEffect(() => {
        if(props?.selectedProduct?.campaign_icon){
            setSelectedSource(props?.selectedProduct?.campaign_icon);
        }
    },[props?.selectedProduct])

    return (
        <>
        {/* <ModalComponent 
        data={{
            toggleIconState:toggleIconState,
        }}
        iconSelectionComponent={CampaignIconSelection}
        heading={'Campaign Icon Selection'}
         ref={childRef} /> */}
        <CampaignIconSelection
        toggleIconState={toggleIconState}
        heading={'Select Icon'}
         ref={childRef} />
        <div className='col-12 card mt-3'>
            <div className='card-body'>
            <label className="form-control-label text-black text-bold" >
                            Options
                        </label>
                {/* <div className="form-group"><label className="form-control-label text-black text-bold" >
                    Campaign Title <span className='text-danger'>*</span>
                </label>
                    <input
                        {...register("campaign_title", { required: true })}
                        placeholder="Campaign Title"
                        type="text" className="form-control-alternative form-control" />
                </div> */}
                {/* {errors?.campaign_title && errors?.campaign_title?.type === 'required' ? <FieldError message={'Please fill out this field'} /> : ''} */}
                <div className='mt-2'>
                    <label className="form-control-label d-flex align-items-center" >
                        <input {...register("active_campaign_for_kiosk", {
                            value: true
                        })} className='me-1' type="checkbox" /> Display Product on Kiosk/Product Page
                    </label>
                </div>
                <div className='mt-2'>
                    <label className="form-control-label d-flex align-items-center" >
                        <input {...register("allow_quick_donate_on_kiosk")}
                         className='me-1' type="checkbox" /> Allow Quick Donate on Kiosk
                    </label>
                </div>

                <div className='mt-1'>
                    <label className="form-control-label d-flex align-items-center">
                    <input {...register("is_display_campaign_title", {
                        value: false
                    })} className='me-1' type="checkbox" /> Hide Title
                    </label>
                </div>
                <div className="form-group mt-1">
                    <label className="form-control-label d-flex align-items-center" >
                        <input
                            {...register('is_subtitle', {
                                value: false
                            })}

                            className="me-1" type="checkbox" /> Subtitle
                    </label>
                    {
                        fieldsWatcher.is_subtitle === true ?
                            <input
                                {...register('subtitle', {
                                    required: true
                                })}
                                placeholder="Enter Subtitle"
                                type="text" className="form-control-alternative form-control mb-3" /> :
                            ''
                    }
                    {errors?.subtitle &&
                        errors?.subtitle?.type === 'required' ?
                        <div className='mb-2'><FieldError message={'This field is required'} /></div> : ''}

                </div>
                
                <div className='mt-1'>
                   
                    <label className="form-control-label d-flex align-items-center">
                    <input {...register("is_display_logo", {
                        value: false
                    })} className='me-1' type="checkbox" /> Display Logo
                    </label>
                </div>
                <div className="form-group mt-1">
                    <label className="form-control-label d-flex align-items-center" >
                        <input
                            {...register('is_header_background_color', {
                                value: false,
                                // onChange: ((e) => {
                                //     resetColorOnUncheck(e);
                                // })
                            })}

                            className="me-1" type="checkbox" /> Header Background Color
                    </label>
                    {
                        fieldsWatcher.is_header_background_color === true ?
                        <div className='d-flex justify-content-start align-items-center mb-3'>
                        <div className='w-20 me-2'>
                            <input id='colorPicker' type="color" {...register("header_background_color",{
                                onChange: ((e) => {
                                    setColor(e);
                                })
                            })}
                            className='form-control w-100' style={{ height: '2.5rem' }} />
                        </div>

                        <div className='w-80'>
                            <input id='txtHeaderColor' 
                            onKeyUp={(e) => {
                                if(e.target.value){
                                    setValue('header_background_color',e.target.value)
                                }
                            }}
                            defaultValue={fieldsWatcher?.header_background_color} placeholder="Header Color"
                                type="text" className="form-control-alternative form-control" /></div>
                    </div> :
                            ''
                    }

                    {errors?.header_background_color &&
                        errors?.header_background_color?.type === 'required' ?
                        <div className='mb-2'><FieldError message={'This field is required'} /></div> : ''}
                </div>  

                <div className='mt-1'>    
                        <div className='d-flex align-items-center justify-content-between'>
                            <label className="form-control-label d-flex align-items-center">
                                <input {...register("is_campaign_icon", {
                                    value: false
                                })} className='me-1' type="checkbox" />Product Icon
                                
                              

                            </label>
                            
                            {
                                fieldsWatcher?.is_campaign_icon ?
                                    <Button variant="ma-primary" onClick={(() => {
                                        if(selectedSource){
                                            setSelectedSource("");
                                            childRef.current.handleClose();
                                        }else{
                                            handleClick()
                                            
                                        }
                                        
                                    })} className='mb-0'>
                                        {selectedSource ? 'Remove Icon' : 'Select Icon'}
                                    </Button> : ''
                            }
                            
                        </div>

                        {
                                    selectedSource && fieldsWatcher?.is_campaign_icon ? 
                                    <div className='col-2'>
                                            <img className='w-50' src={ENVIRONMENT_CONSTANT.CAMPAIGN_ICON_URL+ (selectedSource?.includes('carpeting') ? selectedSource.replace('.jpg.jpg','.jpg') : selectedSource)} alt='Icon' />
                                    </div>
                                     : ''
                                }

                </div>

            </div>
        </div>
        </>
        
    );
});

export default ProductOptions;