export const DEFAULT_PLEDGE_EMAIL_BODY = `
    <p>Dear {donor_first_name} {donor_last_name},</p>
    <p>You Have Pledged {amount}</p>
    <p>Thank you for your pledge, Below is the link attached, use that to complete your donation as soon as possible.</p>
    <p>{donation_link}</p>
    <p>Thank you,</p>
    <p>{organization_name}</p>
`;
export const DEFAULT_PLEDGE_EMAIL_SUBJECT = `You have pledged to donate for {organization_name}`;

export const DEFAULT_EMAIL_BODY = `
    <p>Dear {donor_first_name},</p>
    <p><br></p>
    <p>This is a receipt for your generous donation to {organization_name}.</p>
    <p><br></p>
    <p>{donation_details}</p>
    <p><br></p>
    <p>Thank you,</p>
    <p><br></p>
    <p>{organization_name}</p>
`;

export const DEFAULT_EMAIL_SUBJECT = `Thanks for donating to {organization_name}`;
