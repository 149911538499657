import axios from 'axios';
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AUTHENTICATION_ENDPOINT } from "../../../constants/authentication.endpoint";
import { AUTHENTICATE_MASJID, GET_ORGANIZATION_BY_ID } from "../../../constants/endpoints";
import { ENVIRONMENT_CONSTANT } from "../../../constants/env.constant";

function AuthUser() {
    // let url = ENVIRONMENT_CONSTANT.API_ENDPOINT +'session/authenticate/';
    let url = AUTHENTICATION_ENDPOINT;
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();
    const [authText, setAuthText] = useState('Authenticating User.');

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if(authText === 'Extracting Information.'){
    //             setAuthText('Validating Credentials.')
    //         }else if(authText === 'Validating Credentials.'){
    //             setAuthText('Authenticating User.')
    //         }else if(authText === 'Authenticating User.'){
    //             setAuthText('Authentication Sucessful, Redirecting.')
    //         }else{
    //             // clearInterval(interval);
    //             localStorage.setItem('userInfo', JSON.stringify({email:'wasim@live.com',masjid_id:'dfsfd-3423-s556dfsd-fsdf'}));
    //             navigate('/campaigns');
    //         }
    //     }, 1000);

    //     return () => clearInterval(interval);
    // }, [authText]);

    const authenticateUser = () => {
        axios.get(AUTHENTICATE_MASJID, {
            headers:{
                Authorization:`Bearer ${token}`,
            },
            maxRedirects:0
        })
        .then((res) => {
            if(res?.data?.status && res?.data?.response?.masjid_id){
                // res.data.response.image_url =  './img/masjid/masjid_noor.png';
                // res.data.response.masjid_id = res.data.response.uuid;
                const userInfo = res.data.response;
                userInfo.role = 2;
                localStorage.setItem(ENVIRONMENT_CONSTANT.USER_INFO, JSON.stringify(res?.data?.response));
                // localStorage.setItem(ENVIRONMENT_CONSTANT.ORGANIZATION_LOGO, res?.data?.response?.logo_url);
                getOrganization();
                // window.location.href = window.location.origin + '/donations'
            }else{
                navigate('/login');
            }
        }).catch((error) => {
            navigate('/login');
        })
        // let userInfo = await axios.get(url + token);
        // console.log(userInfo.data);
        // if (userInfo?.data?.response?.masjid_id) {
        //     // localStorage.setItem('userInfo', JSON.stringify(userInfo?.data?.response));
        //     // navigate('/payment-processors')
        // } else {
        //     // return <Navigate replace to="/dashboard"/>;
        //     // navigate('/dashboard')
        // }
    }

    //getting organization info
    const getOrganization = () => {
        axios.get(GET_ORGANIZATION_BY_ID)
        .then((res) => {
            if(res?.data?.response?.id){
                localStorage.setItem('orgInfo', JSON.stringify({
                    organization_short_name: res?.data?.response?.organization_short_name,
                    organization_name: res?.data?.response?.organization_name,
                    logo: res?.data?.response?.image_location,
                    organization_email: res?.data?.response?.organization_email,
                    currency: res?.data?.response?.currency
                }));
                window.location.href = window.location.origin + '/donations'
            }else{
                navigate('/login');
            }
        }).catch((error) => {
            navigate('/login');
        })
    }

    useEffect(() => {
        const interval = setTimeout(() => {
            authenticateUser();
            // window.location.href = AUTHENTICATE + token+ `?time=${new Date().getTime()}`
        }, 2000);

        return () => clearInterval(interval);
    }, [])
    return (
        <>
            <div className='col-xl-6 col-lg-10 col-md-12 col-sm-10 col-xs-10
      offset-md-1 offset-sm-1 offset-xs-1 offset-xl-3 mt-10'>
                <div className="card mt-3">
                    <div className="bg-white border-0 card-header p-0">
                        <div className="align-items-center row">
                            <div className="col-12 text-center">
                                {/* <h4 className="mb-0">Welcome</h4> */}
                                <img src='/img/masjid/masjid_logo_auth.png' className="navbar-brand-img w-30" alt="main_logo" />
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                {/* <h6>Welcome To Masjid-Al</h6> */}
                                <img src='/img/masjid/info.gif' className="navbar-brand-img w-40" alt="main_logo" />
                            </div>

                            <div className="col-lg-12 text-center">
                                <p>{authText}</p>
                            </div>
                        </div>
                        {/* <form className="pl-lg-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group"><label className="form-control-label" >
                                        Campaign Title
                                    </label>
                                        <input placeholder="Campaign Title"
                                            type="text" className="form-control-alternative form-control" />
                                    </div>


                                </div>
                                <div className="col-lg-12 mt-3">
                                    <div className="form-group">
                                        <label className="form-control-label">
                                            Language
                                        </label>
                                        <input placeholder="Language" defaultValue={'English'} type="text"
                                            className="form-control-alternative form-control" /></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 mt-3">
                                    <div className="form-group"><label className="form-control-label">
                                        Goal Amount (Optional)
                                    </label><input placeholder="Goal Amount" type="number"
                                        className="form-control-alternative form-control" /></div>
                                </div>
                                <div className="col-lg-12 mt-3">
                                    <div className="text-right position-relative">
                                        <button
                                            type="button" className={`btn btn-ma-primary btn-md w-100`}>
                                            Create Donation Form
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form> */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default AuthUser;