import { Elements, PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { INITIATE_PAYMENT } from '../../constants/endpoints';
import axios from 'axios';
import PaymentButton from './paymen-button';

const publishKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;

function MyComponent(props) {

    const stripePromise = loadStripe(publishKey,{
        stripeAccount:'acct_1MdI5sRNVpgjumqu'
    });

    const appearance = {
        theme: 'stripe',
    };
    
    
    const [clientSecret, setClientSecret] = useState('');

    const getPaymentIntent = (amount) => {
        // GET_PAYMENT_INTENT
        axios.post(INITIATE_PAYMENT, { amount: (1 * 100),stripe_account_id: 'acct_1MdI5sRNVpgjumqu'})
            .then((res) => {
                if (res?.data?.response?.client_secret) {
                    let clientSecret = res?.data?.response?.client_secret;
                    setClientSecret(clientSecret);
                }
            }).catch((error) => {
                alert('Unable to create payment intent');
            })
    }

    useEffect(() => {
        getPaymentIntent();
    },[])
    


    return (
        <div className='text-center mt-10'>
            Pay Directly with
            {
                clientSecret ?
                <Elements stripe={stripePromise}>
                <PaymentButton clientSecret={clientSecret}/>
            </Elements> : ''
            }
            
        </div>
    );
}

export default MyComponent;